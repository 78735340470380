import { useRouter } from 'next/router';
import styles from 'styles/pages/Page.module.css';

export default function error404() {
  const router = useRouter();

  return (
    <div className={styles.page}>
      <h2 className={styles.title}>Página não encontrada</h2>
    </div>
  )
}